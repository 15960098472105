
@import '../../globals/globalStyles.scss';

.camera {
  display: flex;
  flex-direction: column;
  canvas{
    display: none;
  }
}

.camera__view, 
.camera__sensor, 
.camera__output {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  left: 0;
  top: 0;
  align-self: center;
  justify-self: center;
}

.flip {
  transform: scaleX(-1);
  filter: FlipH;
}

.camera__trigger {
  background-color: $white-color;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 8px;
  .camera__trigger--btn {
    width: 90%;
    background-color: $uma-primary;
    color: $white-color;
    font-size: 22px;
    border-radius: 6px;
    font-weight: 600;
    border: none;
    padding: 15px 20px;
    text-align: center;
    margin: auto;
    display: block;
    &:focus {
      outline: none;
    }
  }
}

// Index Container
.testDiabetes__main {

    // Screen Terms and Conditions
    .diabetes__TermsAndConditions {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        .mainTitle {
            max-width: 350px;
            position: relative;
            img {
                position: absolute;
                right: 0px;
                top: 15px;
            }
        }
    }

    .error__tryAgain {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            margin-top: 40px;
            max-width: 200px;
        }
    }

    // Screen Form 
    .diabetes__formContainer {
        padding: 16px;
        text-align: center;
        .diabetes__form {
            width: 100%;
        
            .inputContainer{
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: start;
                position: relative;
                margin: 24px 0;
                .disclaimerBox {
                    box-shadow: $main-shadow;
                    padding: 10px;
                    border-radius: $regular-borders;
                    background-color: $primary-grey;
                    color: $uma-text;
                    display: flex;
                    svg {
                        color: $uma-primary;
                        font-size: 50px;
                    }
                    p {
                        margin: 0 10px;
                        font-size: 16px;
                    }
                }
                .buttonsGroup {
                    margin: 10px 0;
                    .action__btns {
                        width: 90%;
                    }
                }

            }
            .infoBtn {
                position: absolute;
                top: 5px;
                border: none;
                box-shadow: $main-shadow;
                border-radius: 50%;
                background-color: $white-color;
                right: 0;
                .infoIcon {
                    color: $when-dark;
                }
            }
        }
    }

    // Screen Results
    .testDiabetes__content {
        min-height: 90%;
        padding: 20px;
        position: relative;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        flex-direction: column;
        background: $main-back-gradient;
        .resultsBox {
           display: flex;
           flex-direction: column;
           align-items: center;
           .labels {
                .label__info {
                    display: flex;
                    align-items: center; 
                    li {
                        margin: 10px 15px;
                        color: $uma-text;
                        list-style: disc;
                    }
                    button {
                        width: 25px;
                        height: 25px;
                        box-shadow: $main-shadow;
                        border-radius: 50%;
                        border: none;
                        background-color: transparent;
                        &:focus {
                            outline: transparent;
                        }
                        .infoIcon {
                            color: $when-dark;
                            font-size: 15px;
                        }
                    }
                }
            }
            .screen__lowRisk {
                display: flex;
                align-items: center;
                .low__illustration {
                    width: 80px;
                }
            }
        }
        .recommendations {
            div {
                .box {
                    margin: 20px 0;
                    padding: 10px;
                    box-shadow: $main-shadow;
                    display: flex;
                    align-items: center;
                    background-color: $white-color;
                    border-radius: $regular-borders;
                    position: relative;
                    svg {
                        position: absolute;
                        left: 5px;
                        font-size: 16px;
                        width: 20px;
                        color: $uma-primary;
                    }
                    p {
                        font-size: 16px;
                        margin: 0 0 0 25px;
                    }
                }
            }
            .recommendations__card {
                box-shadow: $main-shadow;
                padding: 8px;
                border-radius: $regular-borders;
            }
        }
    
        footer {
            text-align: center;
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }
}

.test__recommendations {
    padding: 0 20px;
    text-align: center;
    .box {
        margin: 20px 0;
        padding: 10px;
        box-shadow: $main-shadow;
        display: flex;
        align-items: center;
        background-color: $white-color;
        border-radius: $regular-borders;
        position: relative;
        svg {
            position: absolute;
            left: 5px;
            font-size: 16px;
            width: 20px;
            color: $uma-primary;
        }
        p {
            font-size: 16px;
            margin: 0 0 0 25px;
        }
    }
}


.modalDiabetes__info {
    text-align: center;
    label {
        font-weight: bold;
    }
    ul {
        li {
            margin: 10px 0;
        }
    }
}

.apexcharts-menu-icon {
    display: none;
}

.camera__output {
    &.flip {
        &.d-none {
            display: none;
        }
    }
}

.camera__trigger {
    padding: 0;
    background-color: transparent;
    bottom: 20px;
    width: 100%;
    box-sizing: border-box;
    .camera__trigger--btn {
        background: #137DED; // color primary highlighted
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
    }
}