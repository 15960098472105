h1 {
    font-family: var(--font-roboto), Helvetica Neue, sans-serif;
    text-align: center;
    margin-bottom: 40px;
    color: var(--color-white);
}

.timer-wrapper {
    display: flex;
    justify-content: center;
}

.time-wrapper {
    position: relative;
    width: 80px;
    height: 60px;
    font-size: 48px;
    font-family: var(--font-roboto), Helvetica Neue, sans-serif;
}

.time-wrapper .time {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0);
    opacity: 1;
    transition: all 0.2s;
    color: var(--color-white);
}

.time-wrapper .time.up {
    opacity: 0;
    transform: translateY(-100%);
    color: var(--color-white);
}

.time-wrapper .time.down {
    opacity: 0;
    transform: translateY(100%);
    color: var(--color-white);
}
  