
.camera {
  display: flex;
  flex-direction: column;
}

.cameraSensorFlipped { 
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  left: 0;
  top: 0;
  align-self: center;
  justify-self: center;
  position: absolute;
  z-index: 0;
}

.cameraSensorFlipped {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    left: 0;
    top: 0;
    align-self: center;
    justify-self: center;
    transform: scaleX(-1);
    filter: FlipH;
}

.cameraTrigger {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 8px;
    z-index: 1;
}

.cameraTriggerBtn {
    width: 90%;
    background: var(--color-primary);
    color: var(--color-white);
    border: none;
    padding: 15px 20px;
    text-align: center;
    margin: auto;
    display: block;   
}

.cameraTriggerBtn:focus {
    outline: none;
}

.countDown {
  position: fixed;
  top: 25%;
  left: calc(50% - 150px);
}