/** New Colors Occi 1.0 **/
/* Brand colors */
$color-primary: #3562FF;
$color-primary--hover: #002DE3;
$color-primary--pressed: #C4C8FE;
$color-secondary: #A13DDF;
$color-secondary--hover: #6412CB;
$color-secondary--pressed: #DEBCF3;
$color-tertiary: #FFD300;
$color-tertiary--hover: #FFD300;
$color-tertiary--pressed: #FFF8BF;
/* Text colors */
$color-text-primary: #323338;
$color-text-secondary: #6F6F6F;
$color-text-link: #3562FF;
$color-text-error: #E71D1D;
/* Background color */
$color-background-light: #FEFEFE;
/* State Colors */
$color-state-sucess: #259B38;
$color-state-error: #E71D1D;
$color-state-warning: #FFB700;
/* State Colors Hover */
$color-state-sucess-hover: #268a37;
$color-state-error-hover: #d22525;
$color-state-warning-hover: #e4a60a;
/* Greys (not gays) */
$color-grey-1: #333333;
$color-grey-2: #4F4F4F;
$color-grey-3:#828282;
$color-grey-4: #BDBDBD;
$color-grey-5: #E0E0E0;
$color-grey-6: #F2F2F2;

// NEW VARS ÜMA V2

/* Occipital Figma Design */

/* Brand colors */
$color-primary: #3562FF;
$color-primary--hover: #002DE3;
$color-primary--pressed: #C4C8FE;
$color-secondary: #A13DDF;
$color-secondary--hover: #6412CB;
$color-secondary--pressed: #DEBCF3;
$color-tertiary: #FFD300;
$color-tertiary--hover: #FFD300;
$color-tertiary--pressed: #FFF8BF;
/* Text colors */
$color-text-primary: #323338;
$color-text-secondary: #6F6F6F;
$color-text-link: #3562FF;
$color-text-error: #E71D1D;
/* Background color */
$color-background-light: #FEFEFE;
/* State Colors */
$color-state-sucess: #259B38;
$color-state-error: #E71D1D;
$color-state-warning: #FFB700;
/* State Colors Hover */
$color-state-sucess-hover: #268a37;
$color-state-error-hover: #d22525;
$color-state-warning-hover: #e4a60a;
/* Greys (not gays) */
$color-grey-1: #333333;
$color-grey-2: #4F4F4F;
$color-grey-3:#828282;
$color-grey-4: #BDBDBD;
$color-grey-5: #E0E0E0;
$color-grey-6: #F2F2F2;


// OLD VARS ÜMA V2
$uma-primary: #3562FF;
$uma-primary-opacity: #0a6dd748;
$uma-color-primary-highlight: #137DED;
$uma-primary-light: #619BFF;
$uma-primary-dark: #0043A5;
$uma-secondary: #A13DDF;
$uma-pink: #E9008C;
$uma-text: #292929;
$uma-text-secondary: #5C5C5C;
$white-color: #FFFFFF;
$uma-correct: #009042;
$uma-correct-disabled: #80C7A1;
$uma-input: #B6B6B644;

$main-back-gradient: linear-gradient(198.95deg, #E9F8FF 0%, rgba(176, 212, 228, 0) 100%) !important;
$uma-accent-color: #6048BF;
$uma-accent-disabled-color: rgba(96, 72, 191, 0.6);
$uma-font-size-regular: 18px;
$uma-font-size-title: 30px;
$uma-error: #D80000;
$uma-button: #0A6DD7;
$uma-regular-borders: 15px;

/* OLD Colors */
$primary-blue: #97c1d3;
$primary-cyan: #3fd6d2;
$primary-orange: #f8bd1d;
$secondary-color: #64d6d3;
$secondary-blue: #b0d4e4;
$secondary-cyan: #51e3e0;
$secondary-orange: #e0a612;
$secondary-white: #dff4fa;
$primary-grey: #c5d6e2;
$black: #686464;
$real-black: #000;
$when-dark: #516271;
$inactive-primary: #d3eaf4;
$inactive-secondary: #eff8ff;
$data-profile: #989b9f;
$main-gradient-figma: linear-gradient(210.36deg, #b0d4e4 0%, rgba(176, 212, 228, 0) 100%);
$input-background-color: #f3f4f9;
$text-color: #a5bacb;
$text-green: #8eaec9;
/* Colors */
$yellow-opaque: #f8bd1d;
$header-color: #0071f2;
$secondary-background: #1565c0;
$water-blue: #4fa1d1;
$shiny-blue: #0A6dd7;
$dark-blue: #0465bd;
$light-blue: #77c4f1ab;
$gray-header: #b5cdd8;
$white-color: #fff;
$gray-font: #696969;
$grey-navigation-color: #a3bdcc;
$gray-navigation-color: #a3bdcc;
$gray-body-background: #ebebeb;
$special-grey: #6c757d;
$modal-back: #d1d1d1;
$border-light: #cacaca;
$lighter-grey: #b6b6b6;
$green-low: #deffb3;
$yellow-high: #ffcc00;
$orange-high: #ff9933;
$green-high: #00e676;
$red-high: #ef5350;
/* MEDAPOINTMENTS VARS */
$deep-blue: #265985;
$deep-blue-b: #1976d2;
$deep-blue-new: #008290;
/* Borders */
$regular-borders: 6px;
$button-borders: 15px;

$rounded-elements: 100px;
$light-grey-border: #e4f4ff;
$grey-border: #B6B6B6;
/* Card styles */
$card-bg: #3fd6d2;

/* Shadows */
$main-shadow: 0px 4px 8px rgba(103, 151, 195, 0.24);
$dark-shadow: 0px 4px 12px rgba(0, 0, 0, 0.24);
$blured-box-shadow: 0px 7px 20px rgba(138, 138, 138, 0.24);
$blured-box-shadow-inverted: 0px 0px 20px #d8d8d8;
$select-shadow: 0px 1px 14px rgba(126, 147, 163, 0.25);

/* Background gradient */
$bg-yellow: #f7f6ca;
$bg-cyan: #41ccc9;
$bg-light: #eff8ff;
$violet-gradient: #c1cde4;

/* Gradient */

$universal-gradient: linear-gradient(to bottom right, #023167, #017bbc);
$pami-color: #1349a1;
$reduced-back-gradient: linear-gradient(
	$violet-gradient 1%,
	rgba(209, 230, 202, 0.74) 10%,
	rgba(65, 204, 201, 0.2) 30%,
	$white-color
) !important;	

/* Buttons */
$main-shadow: 0 1px 4px rgba(239, 239, 239, 0.16), 0 1px 4px rgba(0, 0, 0, 0.23);
$general-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
$middle-shadow: 0 10px 30px 0 rgba(180, 180, 180, 0.53);
$neumorphism-shadow: 0px 4px 50px rgba(126, 147, 163, 0.25);
$large-modal-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);

/* Buttons */
$blue-button: #42a5f6;
$btn-general-padding: 10px;
$btn-general-width: 90%;

$gray-font: #696969;
$extra-gray: #a8c3dc;
$gray-navigation-color: #c6dbe8;
$gray-body-background: #ebebeb;
$special-grey: #6c757d;
$modal-back: #5a5a5ac9;

$text-super-dark: #1E1E1E;
$text-gray-blue: #719397;
$text-dark: #6f6f6f;
$text-light: #bebebe;

$buttons-green: #2bbbad;
$green-uma: #36a853;
$green-low: #deffb3;

$pink: #dc69a9;
$red-low: #deffb3;

$yellow-high: #ffcc00;
$orange-high: #ff9933;
$violet-high: #5c5286;
